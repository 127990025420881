import { useState, useEffect } from 'react';
import { Form, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import useForm from '../../../utils/formHelper';
import TextField from '@mui/material/TextField';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import classServices from '../../../services/classServices';
import lectureServices from '../../../services/lectureServices';
import {
  candidateProfileChecklistSchema,
  candidateProfileValidation,
} from '../../../schema/schema';
import Button from '../../common/Button'
import ScannerImage from '../../../components/common/ScannerImage';
import { setNotification } from '../../../utils/store/uiSlice';


const JobDetails = ({ title, data, announcementId, visible, setVisible, setJobpopup }) => {

  const [content, setContent] = useState();
  const [screening, setScreening] = useState();
  const [loading, setLoading] = useState(false);
  const [check, setCheck] = useState(false);
  const [candidateCv, setCandidateCv] = useState();
  const [isJoin, setIsJoin] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const { profilecheckId } = useParams(); 
  const navigate = useNavigate();

  const classId = useSelector((store) => store.classes?.currentClass?.class?._id);

  const popHandleClose = () =>{
    setJobpopup(false);    
  }

  

  

  useEffect(() => {       
    lectureServices.dataScreening(classId, profilecheckId, announcementId).then((res) => {     
      if(res?.success){
        setLoading(false);
        // Step 1: Remove excess escaping
        if (res?.success?.screening) {         
          // let screeningData = res.success.screening;
          // screeningData = screeningData.replace(/^\"/, '');  // Remove starting quote
          // screeningData = screeningData.replace(/\"$/, ''); 
          // screeningData = screeningData.replace(/\\"/g, '"'); 
          // screeningData = screeningData.replace(/\\\\/g, '\\');
           //let parsedData = JSON.parse(res?.success?.screening);
          // console.log('screeningData',parsedData)

          const Result = res?.success?.screening
      .replace(/\n\n/g, "\n\n") // Paragraphs separated by double newlines
      .replace(/\\n/g, "\n") // Handle escaped newlines
      .replace(/\*\*/g, "") // Remove bold markers (if any)
      .replace(/\*/g, "- "); // Replace * with bullet points

    let formattedResponse = Result.replace(/\\n/g, " ").replace(/\n/g, " ");

    // Step 2: Remove backslashes
   let finalResponse = formattedResponse
      .replace(/\\/g, "")
      .replace(/^"+|"+$/g, "");

   let finalResult = finalResponse.replace(/""/g, '"');

          setScreening(finalResult);
        } else {
          setScreening('');
        }
        
        
      }
    });
    setContent(data)
  }, [screening]);

  useEffect(() => {
    descriptionFormik.setFieldValue('description', content);
    descriptionFormik.setFieldValue('screening', screening);
    descriptionFormik.setFieldValue('title', title);
  }, [content, screening]);
  
  const descriptionFormik = useFormik({
    initialValues: {
      description: JSON.stringify(content, null, 2),
      screening: screening,
      title: title,
    },
    //validationSchema: descriptionSchema,
    onSubmit: (values) => { 
      setLoading(true);
      setCheck(true);         
      if (values?.description && values?.screening) {
          lectureServices.screeningData(classId, announcementId, profilecheckId, values).then((res) => {
            if(res?.success){
              setLoading(false);                            
              setScreening(res?.success);
              //setJobpopup(false);
              dispatch(setNotification({ success: true, message: 'AIs Analysis Data is saved....'}));
            }
          });
      } else if (values?.description && (!values?.screening || values.screening === 'undefined')) {
        lectureServices.screeningProfilecheck(profilecheckId, values).then((res) => {
          if(res?.success){
            setLoading(false);                        
            setScreening(res?.success);
            //setJobpopup(false);
            dispatch(setNotification({ success: true, message: 'AI Analysis Data is saved....'}));
          }
        });
      }
    },
  });  

  return (
    <>
      {visible && (
        <div className="fixed top-0 left-0 w-full bg-black z-[1] bg-opacity-20 h-full flex items-center justify-center p-5 transition-colors">
          <div
            id="pop"
            className="bg-primary md:min-w-[500px] max-w-[500px] rounded-t-lg"
          >
            <div className=" flex w-full justify-end pe-3">
              <i
                className="fa-solid fa-xmark text-[2rem] text-white cursor-pointer my-2 hover:animate-spin"
                onClick={() => setVisible(false)}
              ></i>
            </div>
            <div className="bg-white rounded-t-lg p-5 transition">
              <ul className="flex gap-3 font-bold text-textColor">                
                <li
                  className={`cursor-pointer ${
                    isJoin ? '' : 'border-b-[3px] border-primary'
                  }`}
                  onClick={() => setIsJoin(true)}
                >
                 Job Profile Details
                </li>
              </ul>
             
              <Form onSubmit={descriptionFormik.handleSubmit} className="flex flex-col">
              
              {loading === true? (<ScannerImage />): ""} 
                <div>
                    <input id="title" readOnly={true} className={`w-full font-mono mt-2 p-3 tracking-wider
                        rounded outline-none border-b-2 border-b-primary text-slate-600"`}                                          
                        value={descriptionFormik.values.title}
                         />
                    </div>
              <label htmlFor="screening" className="font-bold text-textColor">Company Requirenment Profile Data<span className='text-red-500'>{descriptionFormik?.errors?.description}</span></label>  
                    
                    <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">                    
                    <textarea
                        name="description" id="description" rows="4" readOnly={true} className={`w-full font-mono mt-2 p-3 tracking-wider
                        rounded outline-none border-b-2 border-b-primary text-slate-600"`}
                        defaultValue={descriptionFormik.values.description}                        
                        value={descriptionFormik.values.description}
                         ></textarea> 
                  </div>
                  {screening?
                  (<><label htmlFor="screening" className="font-bold text-textColor">Screening AI Data<span className='text-red-500'>{descriptionFormik?.errors?.description}</span></label>  
                  <div className="flex relative border-b-4 border-primary rounded p-2 shadow transition bg-white">                  
                    <textarea
                        name="screening" id="screening" rows="4" readOnly={true} className={`w-full font-mono mt-2 p-3 tracking-wider
                        rounded outline-none border-b-2 border-b-primary text-slate-600"`}
                        defaultValue={descriptionFormik.values.screening}                        
                        value={descriptionFormik.values.screening}
                         ></textarea> 
                  </div> </>) : "" }
                  { profilecheckId?
                      (<div className="mt-2">
                      {check === true? 
                        (<button type="submit" className="btn bg-indigo-500 hover:bg-primary rounded-full py-2 px-5 mr-5 float-right md:float-none">
                        Save Screening Data
                        </button>) : '' }
                        {screening?
                        (<button type="submit" className="btn bg-indigo-500 hover:bg-primary rounded-full text-white py-2 px-5 mr-5 float-right md:float-none">
                        Screening Again Start
                         </button>): (<button type="submit" className="btn bg-indigo-500 hover:bg-primary rounded-full text-white py-2 px-5 mr-5 float-right md:float-none">
                     Start Screening
                      </button>)}
                      </div>) : '' }
                      {/* <Button type='submit' className="mt-2 btn overflow-hidden bg-primary hover:bg-indigo-600 px-1 py-1 rounded text-white font-bold text-center shadow-sm shadow-shadow uppercase">Start Screening</Button> */}
                  </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default JobDetails;
