import axios from "axios";
import { getToken } from "../utils/storageHelper";
import { BASE_URL } from "../constant/constant";

const setAxiosToken = () => {
  const token = getToken();
  axios.defaults.headers.common["Authorization"] = token;
};

const lectureServices = {
  makeRequest: async (url, method, data) => {
    try {
      setAxiosToken();
      const response = await axios({
        url,
        method,
        data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      console.log(
        "%c server " + error.message,
        "color: green; font-weight:bold;"
      );
    }
  },
  allCandidateCvs: async (classId) => {
    setAxiosToken();
    return axios
      .get(`${BASE_URL}/scancv/${classId}/allcandidatecvs`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  allStudents: async (classId) => {
    setAxiosToken();
    return axios
      .get(`${BASE_URL}/classes/${classId}/students`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  acceptRequest: async (classId, studentId) => {
    setAxiosToken();
    return axios
      .patch(`${BASE_URL}/classes/join`, {
        studentId: studentId,
        classId: classId,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err, "accept request");
      });
  },
  rejectRequest: async (classId, studentId) => {
    setAxiosToken();
    return axios
      .delete(`${BASE_URL}/classes/join?class=${classId}&&student=${studentId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err, "accept request");
      });
  },
  removeFromClass: async (classId, studentId) => {
    setAxiosToken();
    return axios
      .delete(`${BASE_URL}/classes/${classId}/students?student=${studentId}`)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  createAssignment: async (classId, values) => {
    setAxiosToken();
    return axios
      .post(
        `${BASE_URL}/classes/${classId}/assignments/`,
        { classId, ...values },
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((res) => {
        return res.data;
      });
  },
  allAssignments: async (classId) => {
    setAxiosToken();
    return axios
      .get(`${BASE_URL}/classes/${classId}/assignments/`)
      .then((res) => res.data);
  },
  allOrders: async (classId, userId) => {
    setAxiosToken();
    return axios
      .get(`${BASE_URL}/classes/${classId}/${userId}/allorders/`)
      .then((res) => res.data);
  },
  getUserLocked: async (classId, userId, isLocked) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/classes/${classId}/user/${userId}`, {
        isLocked,
      })
      .then((res) => {
        return res.data;
      });
  },
  getAssignment: async (classId, assignmentId) => {
    return lectureServices.makeRequest(
      BASE_URL + `/classes/${classId}/assignments/${assignmentId}`,
      "GET"
    );
  },
  getProfileCv: async (profilecheckId) => {
    return lectureServices.makeRequest(
      BASE_URL + `/profilecheck/${profilecheckId}`,
      "GET"
    );
  },

  getScanAIData: async (profilecheckId) => {
    return lectureServices.makeRequest(
      BASE_URL + `/profilecheck/scanaidata/${profilecheckId}`,
      "GET"
    );
  },

  candidateScanAI: async (profilecheckId, values) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/profilecheck/${profilecheckId}/candidateAIdetails`, {
        profilecheckId,
        values,
      })
      .then((res) => {
        return res.data;
      });
  },

  screeningProfilecheck: async (profilecheckId, values) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/profilecheck/${profilecheckId}/screeningProfile`, {
        profilecheckId,
        values,
      })
      .then((res) => {
        return res.data;
      });
  },

  dataScreening: async (classId, profilecheckId, announcementId) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/profilecheck/${profilecheckId}/dataScreening`, {
        classId,
        announcementId,
      })
      .then((res) => {
        return res.data;
      });
  },

  screeningData: async (classId, announcementId, profilecheckId, values) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/profilecheck/${profilecheckId}/screeningData`, {
        classId,
        announcementId,
        profilecheckId,
        values,
      })
      .then((res) => {
        return res.data;
      });
  },

  compareProfilecheck: async (profilecheckId, values) => {
    setAxiosToken();
    return axios
      .post(BASE_URL + `/profilecheck/${profilecheckId}/compareprofilecheck`, {
        profilecheckId,
        values,
      })
      .then((res) => {
        return res.data;
      });
  },
  assignCandidateId: async (classId, assignmentId, values) => {
    setAxiosToken();
    return axios
      .post(
        BASE_URL +
          `/classes/${classId}/assignments/${assignmentId}/assignCandidate`,
        { classId, assignmentId, values }
      )
      .then((res) => {
        return res.data;
      });
  },
  updateAssignment: async (classId, assignmentId, values) => {
    return lectureServices.makeRequest(
      BASE_URL + `/classes/${classId}/assignments/${assignmentId}`,
      "PATCH",
      values
    );
  },
  allSubmissions: async (classId, assignmentId) => {
    setAxiosToken();
    return axios
      .get(
        BASE_URL + `/classes/${classId}/assignments/${assignmentId}/submissions`
      )
      .then((res) => {
        return res.data;
      });
  },
  getTodayAttendance: async (classId) =>
    lectureServices.makeRequest(
      `${BASE_URL}/classes/${classId}/attendance`,
      "GET"
    ),
  getDashboard: async (classId) =>
    lectureServices.makeRequest(
      `${BASE_URL}/analytics/class?classId=${classId}`,
      "GET"
    ),
  allQuestionSubmission: async (questionId) =>
    lectureServices.makeRequest(
      `${BASE_URL}/questions/${questionId}/submission`
    ),
};

export default lectureServices;
