import { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from 'react-router-dom';
import Button from '../../common/Button';
import screeningServices from '../../../services/screeningServices';
import { setNotification } from '../../../utils/store/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import JobDetails from '../../../components/classroom/common/JobDetails';

const AnnouncementCard = (props) => {
  const {
    profileId,
    assigned,
    count_job = 0,
    date,
    company_name = 'Company Name',
    title = 'Title',
    description = 'The description goes here',
    buttonName,
    action,
    icon = 'ri-play-circle-line',
    theme,
    id,
    handleClick,    
    status: initialStatus,
  } = props;



  const { classId, assignmentId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()



  const { user } = useSelector(store => store?.user)

  const [jobpopup, setJobpopup] = useState(false);

  const [color, setColor] = useState();

  useEffect(()=>{
    let c = 'gray'
    switch(theme) {
      case 'black': c = 'gray'; break;
      case 'danger': c = 'red'; break;
      case 'success': c = 'green'; break;
      case 'warning': c = 'orange'; break;
      case 'hope': c = 'indigo'; break;
      default: c = 'gray'
    }
    setColor(c); 

  }, [theme])

  if (!color) return <div className="p-3 px-4 h-[100px] shimmer min-w-[200px] md:min-w-[250px] rounded-md max-w-[400px] shadow shadow-shadow"></div>

  const JobDetailsPopupOpen = () => {
        setJobpopup(true);
  }

  

  return(
    <>
    <JobDetails title={title} visible={jobpopup} setJobpopup={setJobpopup} announcementId={id} data={description} setVisible={setJobpopup} />
    <div className="p-3 px-4 justify-between  min-w-full md:min-w-[350px] rounded-md flex md:gap-3 max-w-[400px] shadow transitions shadow-shadow hover:max-w-full">
    {/*  */}
      <div>      
      <h4 className="text-sm md:text-2xl text-ellipsis max-w-[250px] max-h-[2rem] overflow-hidden">{`Name: `}{company_name}</h4>
        <h2 className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[16px] md:text-normal">{`Desigination: `}{title}</h2>
        <div className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[12px] md:text-normal">
         {`Job Description: `} <a href="#" style={{padding: 0, color: '#007bff', marginTop: '-1px', marginRight: '80px', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none'}} className="btn px-2 py-1 rounded-md bg-primary text-white mt-1 float-right mr-1" onClick={JobDetailsPopupOpen}>
        GET Details
      </a>
        </div> 
        <div className="md:max-w-[450px] max-h-[50px] max-w-[220px] overflow-hidden ellipsis text-[12px] md:text-normal">
        {`AnnounceData: `} {date}
        </div>
        <Link to={`/company/${classId}/dashboard/${id}/assigncandidatecvs`}>
        <p style={{padding: 0, color: '#007bff', marginTop: '-1px', marginRight: '80px', textDecoration: 'underline', cursor: 'pointer', background: 'none', border: 'none'}} className="font-bold text-sm md:text-1xl text-ellipsis min-w-[150px] max-w-[1.5rem]">{`Total Candidate Profile's Assigned: `}{count_job}</p>
        </Link>
        {/* {buttonName && <Link to={action}><p className={`overflow-hidden whitespace-nowrap text-white px-2 py-1 mt-3 min-w-[150px] max-w-[2rem] rounded uppercase font-bold text-center bg-${color}-500`}>{buttonName}</p></Link>} */}
        
        {!profileId ? "" : (
          <div>
            {!assigned.includes(profileId) ? (
              <Button onClick={() => handleClick(id)}>
                <p className={`overflow-hidden whitespace-nowrap text-white px-2 py-1 mt-3 min-w-[150px] max-w-[2rem] rounded uppercase font-bold text-center bg-${color}-500`}>
                  Assign Me
                </p>
              </Button>
            ) : (
              
                <p className={`overflow-hidden whitespace-nowrap text-white px-3 py-1 mt-2 min-w-[170px] max-w-[5rem] rounded uppercase font-normal text-center bg-gray-300`}>
                  Already Assigned
                </p>
              
            )}
          </div>
        )}
        {/* <h7 className="text-sm md:text-1xl text-ellipsis max-w-[270px] max-h-[1rem] overflow-hidden">{`Opening Status `}</h7>
        <select
                name="status"
                id="status"
                disabled={user?.type == 'Company'? false: true}                
                onChange={(e) => handleSelect(e, id)}                
                className={`overflow-hidden whitespace-nowrap text-white px-2 py-1 mt-2 min-w-[140px] max-w-[1rem] rounded uppercase font-bold text-center bg-gray-400`}
              >
                <option value="">Select</option>
                <option value="Open">Open</option>
                <option value="Processing">Processing</option>                         
                <option value="Close">Close</option>                            
              </select> */}
      </div>
      
      <div className={`flex justify-center items-left md:p-0 text-${color}-500`}>
      <i className={`${icon} text-3xl md:text-5xl`}></i>      
      </div>       
    
    {/* </Link> */}
    </div>
    </>
  )
}

export default AnnouncementCard;